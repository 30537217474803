
  import { H3, Popover } from 'components/generic'

  export default
    props:
      title: String
      items: Array
      params: Object

    data: ->
      location: window.location

    components:
      H3: H3
      Popover: Popover
